.CallForAction {
    position: relative;
    min-height: 200px;
    background: no-repeat 20px calc(100% - 20px);
    background-size: 85px;
    mask-size: 70px;
}

.CallForActionText {
    font-family: Marcia;
    position: absolute;
    /*background-color: #49a3a8;*/
    /*filter: alpha(opacity=80);*/
    /*opacity: .8;*/
    color: white;
    width: 100%;
    /*height: 80px;*/
    line-height: 30px;
    padding: 20px;
    font-size: 1.8em;
    -webkit-transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    display: inline;

}

.CallForActionText:after {
    font-family: Icons;
    font-size: 20px;
    margin-left: 10px;
    content: "\f061";
    display: inline;
}

.CallForAction:hover .CallForActionText {
    filter: alpha(opacity=80);
    opacity: 1;
}
