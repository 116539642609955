.produktbox {
    background-color: #f6f0e7;
    padding:16px;
    min-height: 260px;
}

.fordele-ikon {
    position: relative;
    min-height: 80px;
    width: 100%;
    background: no-repeat;
    background-size: 80px;
}
