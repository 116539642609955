.bestyrelseMedlem {
  height:100px;
  text-align:center;
  padding-left:100px;
  padding-top:30px;
  background: url(../../images/Letpension-logo-symbol-RGB.png) no-repeat 20px 24px;
}

.bestyrelseTitel {
  height:50px;
  text-align:center;
  padding-top:10px;
  font-size: 13px;
}
