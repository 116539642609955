.ui.segment.ImageLink {
    padding: 0;
    position: relative;
}

.ImageLinkText {
    position: absolute;
    font-family: Marcia;
    bottom: 0;
    text-align: center;
    /*background-color: #49a3a8;*/
    /*filter: alpha(opacity=80);*/
    /*opacity: .8;*/
    color: white;
    width: 100%;
    /*height: 80px;*/
    line-height: 30px;
    padding: 10px;
    font-size: 1.8em;
    -webkit-transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.ImageLinkText:after {
    font-family: Icons;
    font-size: 20px;
    margin-left: 10px;
    content: "\f061";
    display: inline;
}


.ui.segment.ImageLink:hover .ImageLinkText {
    filter: alpha(opacity=100);
    opacity: 1;
}
