.ui.segment.HeaderImage {
    padding: 0;
    position: relative;
}

.ui.segment.HeaderImageMobile {
    padding: 0;
    position: relative;
}

.ui.segment.HeaderImageMobile img {
    /*transform: scale(1.5);*/
}

.HeaderImageText {
    position: absolute;
    max-width: 80%;
    font-family: Marcia, serif;
    font-size: 48px;
    bottom: 30px;
    left: 40px;
    color: white;
    width: 100%;
    line-height: 1.2em;
    padding: 10px;
}

.HeaderImageTextMobile {
    position: absolute;
    max-width: 90%;
    font-family: Marcia, serif;
    font-size: 22px;
    bottom: 0px;
    left: 0px;
    color: white;
    width: 100%;
    line-height: 1.2em;
    padding: 10px;
}

.HeaderImageTrompet {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    color: #e49d3b;
}

.HeaderImageTrompetMobile {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: #e49d3b;
}
