.ui.menu.TopMenu {
    -webkit-transition: all .15s ease-in-out;
    -moz-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    background-color: #f6f0e7;
    border: none;
    box-shadow: none;
}

.Easing img {
    -webkit-transition: all .15s ease-in-out;
    -moz-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

.TopMenu.ui.menu .item {
    font-weight: bold;
    white-space: nowrap !important;
}

.ui.inverted.popup.MenuPopup {
    background-color: #6bb8bc;
}

.ui.inverted.popup.MenuPopup::before {
    background-color: #6bb8bc;
    font-size: 30px;
}

.ui.inverted.popup.MenuPopup {
    top: -10px !important;
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
