.Main {
    min-height: calc(100vh - 52px);
    padding-top: 40px;

    color: black;
}

.ui.header.PageHeader {
    text-transform: uppercase;
}

.ui.header {
    line-height: 1;
}

.ui.button, ui.basic.button {
    border-radius: 4px !important;
}


h1.ui.header, h2.ui.header {
    padding-top: 32px;
    font-family: Marcia, Serif;
    font-size: 56px;
    font-weight: normal;
}

.ui.text.container {
    font-size: 16px;
}

@media ( max-width: 600px) {
    h1.ui.header, h2.ui.header {
        padding-top: 16px;
        font-family: Marcia, Serif;
        font-size: 46px;
        font-weight: normal;
    }

}