.TopMenu {
    height: 70px;
}

.ui.menu .item {

}

.menu.ui.accordion .title:not(.ui) {
    font-size: 17px;
    font-weight: 600;
}

.ui.vertical.menu .menu .item {
    font-size: 17px;
    font-weight: 600;
}

.ui.vertical.inverted.menu .menu .item, .ui.vertical.inverted.menu .menu .item a:not(.ui) {
    color:white;
}


.ui.accordion.menu .title .dropdown.icon:before, .ui.accordion.menu .accordion.menu .title .dropdown.icon:before {
    content: '\f0d9';
}

.ui.accordion.menu .title.active .dropdown.icon:before, .ui.accordion.menu .accordion.menu .title.active .dropdown.icon:before {
    content: '\f0da';
}
