.bullet {
    height: 80px;
}

.klima-logo {
    height: 50px;
}

.klima-logo-nidec {
    height: 32px;
    margin-bottom: 18px;
}
