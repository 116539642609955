/*A:link, A:active, A:visited {*/
/*  color:#1e677b;*/
/*}*/

.harlekin-bg {
    background: url(./images/harlekin-baggrund.png) no-repeat top left !important;
    background-size: 80% !important;
}

h4 {
    margin-bottom: 4px;
}

.link {
    text-decoration: none;
    color:#e49d3b;
}
.link:hover {
    text-decoration: underline;
    color:#e49d3b;
}

.ui.inverted.teal.table th {
    background-color: #49a3a800;
    color:#ffffffff !important;
}

.ui.inverted.teal.table tbody tr {
    background-color: #faf7f4;
    color:black;
}


.ui.inverted.teal.table tbody tr:nth-child(2n) {
    background-color: #f6f0e7;
    color:black;
}
.manchet {
    margin: 0.5rem 0 1.5rem 0;
    font-size: 1.5rem;
    line-height: 1.4em;
    color: #e49d3b;
}

.trompet {
    font-size: 1.5rem;
    margin-bottom: 0.5rem!important;
    color: #6bb8bc;
    font-weight: normal;
    text-transform: uppercase;
}

ul {
    list-style-type: square;
    list-style-position: outside;
    padding-inline-start: 19px;
}

@media ( max-width: 600px) {
    .manchet {
        margin: 0.5rem 0 1.5rem 0;
        font-size: 1.3rem;
        line-height: 1.3em;
        color: #e49d3b;
    }

    .trompet {
        font-size: 1.2rem;
    }

}

.citat {
    margin: 0.5rem -6rem 1.5rem -6rem;
    font-size: 1.3rem;
    font-style: italic;
    line-height: 1.4em;
    color: #6bb8bc;
}

.citat-start {
    font-family: Marcia;
    font-size:56px;
    padding-right: 10px;
}

h3 {
    margin-bottom: 8px;
}
