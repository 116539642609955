.ui.segment.KontaktBoks {
    padding: 0;
    position: relative;
    height: 150px;
}

.KontaktBoksEmne {
    text-align: center;
    padding:35px 0 20px 100px;
    font-weight: bold;
    font-size: 1.2em;
    background: url(../../images/harlekin.gif) no-repeat;
}

.KontaktBoksEmneFullWidth {
    text-align: center;
    padding:35px 0 20px 0;
    font-size: 1.2em;
}

.KontaktBoksTlf {
    position: absolute;
    bottom: 0;
    text-align: center;
    background-color: #1e677b;
    filter: alpha(opacity=80);
    color: white;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 1.5em;
}

.ui.segment.KontaktBoks:hover .KontaktBoksTlf {
    filter: alpha(opacity=100);
    opacity: 1;
}
